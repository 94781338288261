import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route, Switch, Redirect } from 'react-router-dom';

import UserOverview from 'screen/UserOverview';
import UserEdit from 'screen/UserEdit';
import LoginForgot from 'screen/LoginForgot';
import ResetPassword from 'screen/ResetPassword';
import AccountDetails from 'screen/AccountDetails';
import AccountPassword from 'screen/AccountPassword';
import KeyOverview from 'screen/ApiKey/Overview';
import KeyEdit from 'screen/ApiKey/Edit';
import LayoutEdit from 'screen/ApiKey/Layout/Edit';
import NotFound from 'container/NotFound';
import LogOverview from 'screen/Log/Overview';

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store} />;
    };

    redirectFromHome = () => {
        let uri = '/manage/key';
        return <Redirect to={uri} />;
    };

    render() {
        return (
            <Switch>
                <Route exact path="/" render={this.redirectFromHome} />

                <Route path="/log/overview" render={this.route(LogOverview)} />
                <Route
                    path="/account/details"
                    render={this.route(AccountDetails)}
                />
                <Route
                    path="/account/password"
                    render={this.route(AccountPassword)}
                />
                <Route path="/login/forgot" render={this.route(LoginForgot)} />
                <Route
                    path="/user/:id/reset-password/:token"
                    render={this.route(ResetPassword)}
                />
                <Route
                    path="/manage/user"
                    exact
                    render={this.route(UserOverview)}
                />
                <Route path="/manage/user/add" render={this.route(UserEdit)} />
                <Route
                    path="/manage/user/:id/edit"
                    render={this.route(UserEdit)}
                />

                <Route
                    path="/manage/key"
                    exact
                    render={this.route(KeyOverview)}
                />

                <Route
                    path="/manage/key/:id/edit"
                    render={this.route(KeyEdit)}
                />

                <Route
                    path="/manage/key/:id/layout"
                    render={this.route(LayoutEdit)}
                />

                <Route path="/manage/key/add" render={this.route(KeyEdit)} />

                <Route render={this.route(NotFound)} />
            </Switch>
        );
    }
}
