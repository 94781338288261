import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Header } from 'semantic-ui-react';
import RightDivider from '../component/RightDivider';
import { Store } from 'store/Base';
import styled from 'styled-components';

const Left = styled.div`
    display: inline-flex;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
`;


@observer
export default class HeaderRight extends Component {
    static propTypes = {
        content: PropTypes.node.isRequired,
        children: PropTypes.node,
        store: PropTypes.instanceOf(Store), // If given, shows `(store.length)` next to label.
        className: PropTypes.string,
    };

    render() {
        const { children, className, ...rest } = this.props;

        rest.content = (
            <React.Fragment>
                {rest.content}
                {this.renderCount() || null}
            </React.Fragment>
        );

        return (
            <HeaderContainer className={className}>
                <Left>
                    <Header {...rest} />
                </Left>
                <RightDivider />
                {children}
            </HeaderContainer>
        );
    }

    renderCount() {
        const { store } = this.props;

        if (store && store instanceof Store) {
            return store.isLoading
                ? '(...)'
                : ` (${store.length})`;
        }

        if (store && typeof store.length === 'number') {
            return ` (${store.length})`;
        }

        return '';
    }
}
