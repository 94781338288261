import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Store } from 'store/Base';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Loader } from 're-cy-cle';
import { Table, TableHead, TableRow, TableHeader, TableBody } from 're-cy-cle';
import { tint } from 'polished';
import { debounce } from 'lodash';

const StyledTableHead = styled(TableHead)`
    font-size: 13px
`;

const StyledTableFoot = styled.tfoot`
    font-size: 14px;
`;

const ORDER_BY = 'order_by';

const Container = styled.div`
    position: relative;
`;

const StyledLoader = styled(Loader)`
    position: absolute;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
`;

function sortOrderCss(props) {
    if (!props.currentSortOrder) {
        return `
            top: 11px;
            border-top-color: ${tint(0.25, props.theme.buttonPrimaryColor)};
        `;
    }

    if (props.currentSortOrder === 'desc') {
        return `
            top: 11px;
            border-top-color: ${props.theme.buttonPrimaryColor};
        `;
    }

    if (props.currentSortOrder === 'asc') {
        return `
            top: 3px;
            border-bottom-color: ${props.theme.buttonPrimaryColor};
        `;
    }
}

const StyledTableHeaderLeft = styled(TableHeader)`
    position: relative;

    ${props => props.onClick && `
        cursor: pointer;
        padding-left: 20px;

        ::before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border: 8px solid transparent;
            ${sortOrderCss(props)}
            -webkit-transition: 175ms all ease;
            transition: 175ms all ease;
            z-index: 1;

            left: 0;
        }
    `}
`;

// TODO: Figure out why this doesn't work!
// const StyledTableHeader = styled(TableHeader)`
//     position: relative;

//     ${props => props.onClick && `
//         cursor: pointer;
//         padding-${props => props.sortKeyPosition}: 20px;

//         ::before {
//             position: absolute;
//             content: '';
//             width: 0;
//             height: 0;
//             border: 8px solid transparent;
//             ${sortOrderCss(props)}
//             -webkit-transition: 175ms all ease;
//             transition: 175ms all ease;
//             z-index: 1;

//             ${props => props.sortKeyPosition}: 0;
//         }
//     `}
// `;

// function hasSortKey(store, key) {
//     if (typeof store.params[ORDER_BY] === 'string') {
//         return store.params[ORDER_BY].split(',').includes(key);
//     }
//
//     return false;
// }

/**
 * Basic overview table which takes care of rendering headers and looping over
 * store to render rows.
 *
 * Has basic single columns sorting support by clicking on headers.
 *
 * Example usage:
 *
 * <OverviewTable
 *      headings={[
 *          // Callback for label:
 *          { label: () => <Checkbox /> },
 *
 *          // Auto guess label based on description and store.Model:
 *          { attr: 'description' },
 *
 *          // Manually give label, and sortKey:
 *          { label: t('device.field.identifier.label'), sortKey: 'identifier' },
 *
 *          // Simple string:
 *          t('form.actions'),
 *      ]}
 *      item={Item}
 *      store={deviceStore}
 * />
 */
@observer
export default class OverviewTable extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
        item: PropTypes.func.isRequired,
        tfooters: PropTypes.func,
        headings: PropTypes.array.isRequired,
        settings: PropTypes.array,
        renderItem: PropTypes.func,
    }

    debouncedFetch = debounce(this._fetch, 300);

    _fetch(){
        this.props.store.setPage();
    }

    currentSortOrder(store, key) {
        const params = store.params ? store.params : {};

        if (typeof params[ORDER_BY] === 'string') {
            if (params[ORDER_BY].split(',').includes(key)) {
                return 'asc';
            }

            if (params[ORDER_BY].split(',').includes(`-${key}`)) {
                return 'desc';
            }
        }

        return false;
    }

    sort(sortKey) {
        const { store } = this.props;

        if (!store.params) {
            store.params = {};
        }

        switch (this.currentSortOrder(store, sortKey)) {
            case 'asc':
                store.params[ORDER_BY] = `-${sortKey}`;
                break;
            case 'desc':
                delete store.params[ORDER_BY];
                break;
            default:
                store.params[ORDER_BY] = sortKey;
                break;
        }

        if (store.updateUrlParams) {
            store.updateUrlParams();
        }

        this.forceUpdate();
        this.debouncedFetch();
    }

    render() {
        return (
            <Container>
                <Table>
                    <StyledTableHead>
                        <TableRow>
                            {this.props.headings.map(this.renderHeading)}
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {this.props.store.map(this.renderItem.bind(this))}
                    </TableBody>
                    <StyledTableFoot>
                        {this.props.tfooters}
                    </StyledTableFoot>
                </Table>
                {this.props.store.isLoading && <StyledLoader show height={50} />}
            </Container>
        );
    }

    renderItem(model) {
        if (this.props.renderItem) {
            return this.props.renderItem(model);
        }

        const Item = this.props.item;
        return <Item key={model.cid} model={model} {...this.props} />;
    }

    renderHeading = (heading, index) => {
        const { store } = this.props;
        let label = typeof heading === 'object' ? heading.label : heading;
        let sortKey = heading.sortKey;
        const props = heading.props || {};

        if (typeof label === 'function') {
            label = label();
        }

        if (typeof sortKey === 'function') {
            sortKey = sortKey();
        }

        return (
            <StyledTableHeaderLeft
                key={index}
                onClick={sortKey ? () => this.sort(sortKey) : undefined}
                currentSortOrder={this.currentSortOrder(store, sortKey)}
                {...props}
            >
                {label}
            </StyledTableHeaderLeft>
        );
    }
}
