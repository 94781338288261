import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ApiKey } from '../../../store/ApiKey';
import { LayoutItemStore } from '../../../store/LayoutItem';
import LayoutEdit from '../../../container/ApiKey/Layout/Edit';

@observer
export default class LayOutEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    componentWillMount() {
        const id = this.props.match.params.id;
        this.apiKey = new ApiKey({
            id: parseInt(id),
        });
        this.layoutStore = new LayoutItemStore();
    }

    componentDidMount() {
        this.layoutStore.params = {
            '.api_key': this.apiKey.id,
            order_by: 'slug',
            limit: 10000,
        };
        this.apiKey.fetch();
        this.layoutStore.fetch();
    }

    handleBack = () => '';

    render() {
        return (
            <LayoutEdit
                apiKey={this.apiKey}
                layoutItemStore={this.layoutStore}
                onBack={this.handleBack}
            />
        );
    }
}
