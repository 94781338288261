import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AccountPassword from '../container/AccountPassword';

@observer
export default class AccountPasswordScreen extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return <AccountPassword viewStore={this.props.viewStore} />;
    }
}
