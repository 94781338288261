import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TextInput, FormField, IconUpdate, Tooltip } from 're-cy-cle';
import styled from 'styled-components';

const StyledLabel = styled.label`
    text-transform: uppercase;
`;

const Container = styled.div`
    font-size: 12px;
    padding: 0 0 2px;
    opacity: 0.75;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    line-height: 1.45;
`;

class ModifiedLabelText extends Component {
    static propTypes = {
        helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        htmlFor: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    render() {
        return (
            <Container>
                <StyledLabel htmlFor={this.props.htmlFor}>
                    {this.props.children}
                </StyledLabel>
                <div>{this.props.helpText}</div>
            </Container>
        );
    }
}

class ModifiedFormField extends FormField {
    static propTypes = {
        children: PropTypes.node.isRequired,
        label: PropTypes.string,
        helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        // Also accepts an object because MobX arrays are objects.
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        noPadding: PropTypes.bool,
        required: PropTypes.bool,
    };

    renderLabel() {
        if (!this.props.label) return null;

        return (
            <ModifiedLabelText
                helpText={this.props.helpText}
                htmlFor={this.uniqueId}
            >
                <div>{this.props.label}</div>
            </ModifiedLabelText>
        );
    }
}

@observer
export default class ApiKeyLayoutItem extends Component {
    static propTypes = {
        layoutItem: PropTypes.object.isRequired,
    };

    @observable isLoading = false;

    changeValue = (name, value) =>
        this.props.layoutItem.setInput('value', value);

    render() {
        let iconUpdate = '';
        if (this.props.layoutItem.notDefault) {
            const originalMessage =
                'Default: ' + this.props.layoutItem.defaultValue;
            iconUpdate = (
                <Tooltip message={originalMessage} direction="sw">
                    <IconUpdate />
                </Tooltip>
            );
        }

        return (
            <ModifiedFormField
                label={this.props.layoutItem.label}
                helpText={iconUpdate}
                style={{ overflow: 'show' }}
                required
            >
                <TextInput
                    value={this.props.layoutItem.value}
                    onChange={this.changeValue}
                />
            </ModifiedFormField>
        );
    }
}
