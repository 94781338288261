import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, } from 'mobx-react';
import { Button, IconKeyboardArrowRight, IconKeyboardArrowLeft } from 're-cy-cle';
import { ApplyButton } from '../semantic-ui/Button';
import { Store } from 'mobx-spine';
import styled from 'styled-components';
import { observable } from 'mobx';
import { Popup, Input } from 'semantic-ui-react';

const Pages = styled.span`
    padding-left: 5px;
    padding-right: 10px; // Because Button doesn't have a left margin.
`;

const StyledInput = styled(Input)`
    width: 50px;
    margin-right: 10px;
`;

@observer
export default class PaginationControls extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(Store).isRequired,
    };

    @observable newPage = 1;

    handlePrevious = () => {
        this.props.store.getPreviousPage();
    };

    handleNext = () => {
        this.props.store.getNextPage();
    };

    render() {
        const store = this.props.store;

        return (
            <div>
                <Button
                    onClick={this.handlePrevious}
                    disabled={!store.hasPreviousPage}
                >
                    <IconKeyboardArrowLeft />
                </Button>
                <Pages>
                    <Popup
                        hoverable
                        trigger={<Button>{store.currentPage}/{store.totalPages}</Button>}
                        onOpen={() => this.newPage = store.currentPage}
                    >
                        <StyledInput value={this.newPage || ''} onChange={(e, { value }) => this.newPage = parseInt(value)} />
                        <ApplyButton onClick={() => {
                            this.props.store.setPage(this.newPage);
                        }}/>
                    </Popup>
                </Pages>
                <Button onClick={this.handleNext} disabled={!store.hasNextPage}>
                    <IconKeyboardArrowRight />
                </Button>
            </div>
        );
    }
}
