import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Body, ContentContainer, Sidebar } from 're-cy-cle';
import TrippyBackground from '../component/TrippyBackground';
import { Form, Link, Button, FormField, TextInput } from 're-cy-cle';
import { Col, Row, Heading } from 're-cy-cle';

@observer
export default class Login extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
    };

    @observable errorMsg = '';
    @observable
    input = {
        username: '',
        password: '',
    };

    handleSubmit = () => {
        this.errorMsg = '';
        this.props.viewStore
            .performLogin(this.input.username, this.input.password)
            .catch(err => {
                const status = err.response && err.response.status;
                if (status === 403) {
                    this.errorMsg = t('user.login.errors.invalidCredentials');
                } else {
                    console.error(err);
                    this.errorMsg = t('user.login.errors.unknown', {
                        status: status || err.message,
                    });
                }
            });
    };

    handleChangeInput = (name, value) => {
        this.input[name] = value;
    };

    render() {
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground />
                    <Sidebar>
                        <Row middle="xs">
                            <Col xs>
                                <Heading>{t('user.login.title')}</Heading>
                            </Col>
                            <Col>
                                <Link to="/login/forgot">
                                    {t('user.login.forgotPasswordLink')}
                                </Link>
                            </Col>
                        </Row>
                        <Form noValidate onSubmit={this.handleSubmit}>
                            <FormField label={t('user.field.email.label')}>
                                <TextInput
                                    name="username"
                                    type="email"
                                    onChange={this.handleChangeInput}
                                    value={this.input.username}
                                    autoFocus
                                />
                            </FormField>
                            <FormField label={t('user.field.password.label')}>
                                <TextInput
                                    name="password"
                                    type="password"
                                    onChange={this.handleChangeInput}
                                    value={this.input.password}
                                />
                            </FormField>
                            <FormField
                                error={this.errorMsg ? [this.errorMsg] : null}
                            >
                                <Button type="submit" fullWidth>
                                    {t('user.login.loginButton')}
                                </Button>
                            </FormField>
                        </Form>
                    </Sidebar>
                </ContentContainer>
            </Body>
        );
    }
}
