import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { InlineText } from 're-cy-cle';

@observer
export default class UserGroupText extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
    };

    render() {
        const { user } = this.props;

        if (user.isSuperuser) {
            return <span>{t('user.role.superuser')}</span>;
        }
        if (user.groupNames.length < 1) {
            return <InlineText italic>{t('user.role.empty')}</InlineText>;
        }
        return (
            <span>
                {user.groupNames
                    .map(group => t(`user.role.${group}`))
                    .join(', ')}
            </span>
        );
    }
}
