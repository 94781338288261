import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Body, ContentContainer, Content } from 're-cy-cle';
import { Header, Button, Grid, Form, Input } from 'semantic-ui-react';
import { SaveButton } from '../spider/semantic-ui/Button';
import styled from 'styled-components';

const Centered = styled.div`
    text-align: center;
`
const CenteredHeader = styled(Header)`
    text-align: center;
`;;


@observer
export default class AccountDetails extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
        onLogout: PropTypes.func.isRequired,
    };

    @observable debug = false;
    @observable input = {
        passwordOld: '',
        passwordNew: '',
    };
    @observable inputErrors = {};

    constructor(props) {
        super(props);

        this.debug = !!localStorage.getItem('debug');
    }

    handleLogout = () => {
        this.props.viewStore.performLogout().then(this.props.onLogout);
    };

    saveUser = () => {
        const { viewStore } = this.props;
        viewStore.currentUser.save({ onlyChanges: true })
            .then(() => {
                if (viewStore.currentUser._avatar) {
                    return viewStore.currentUser.saveAvatar();
                }
            })
            .then(() => viewStore.showSaveNotification());
    };

    @action
    handlePasswordChange = (e, { name, value }) => {
        this.input[name] = value;
        this.inputErrors = {};
    };

    handlePasswordSubmit = () => {
        const { currentUser } = this.props.viewStore;
        currentUser
            .changePassword(this.input)
            .then(() => {
                this.props.viewStore.showSaveNotification();
            })
            .catch(err => {
                if (err.response && err.response.data.errors) {
                    this.inputErrors = err.response.data.errors;
                }
            });
    };

    render() {
        const user = this.props.viewStore.currentUser;
        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Grid columns={3} divided>
                            <Grid.Column>
                                <CenteredHeader as="h2">
                                    {t('user.account.info.title')}
                                </CenteredHeader>
                                <Form>
                                    <Form.Field>
                                        <label>{t('user.field.firstName.label')}</label>
                                        <input value={user.firstName} readOnly />
                                    </Form.Field>

                                         <Form.Field>
                                        <label>{t('user.field.lastName.label')}</label>
                                        <input value={user.lastName} readOnly />
                                    </Form.Field>

                                    <Form.Field>
                                        <label>{t('user.field.email.label')}</label>
                                        <input value={user.email} readOnly />
                                    </Form.Field>
                                    <Centered>
                                        <SaveButton primary
                                            loading={user.isLoading}
                                            onClick={this.saveUser}
                                            content={t('form.saveButton')}
                                        />
                                    </Centered>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <CenteredHeader as="h2">
                                    {t('user.account.password.title')}
                                </CenteredHeader>
                                <Form>
                                    <Form.Field>
                                        <label>{t('user.changePassword.field.passwordOld.label')}</label>
                                        <Input
                                            type="password"
                                            name="passwordOld"
                                            value={this.input.passwordOld}
                                            onChange={this.handlePasswordChange}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t('user.changePassword.field.passwordNew.label')}</label>
                                        <Input
                                            type="password"
                                            name="passwordNew"
                                            value={this.input.passwordNew}
                                            onChange={this.handlePasswordChange}
                                        />
                                    </Form.Field>
                                    <Centered>
                                        <Button primary
                                            data-test-change-password-save
                                            icon="save"
                                            labelPosition="left"
                                            onClick={this.handlePasswordSubmit}
                                            content={t('form.saveButton')}
                                        />
                                    </Centered>
                                </Form>
                            </Grid.Column>
                            <Grid.Column>
                                <CenteredHeader as="h2">
                                    {t('user.account.logout.title')}
                                </CenteredHeader>
                                <Centered>
                                    <Button primary
                                        icon="sign out"
                                        labelPosition="left"
                                        onClick={this.handleLogout}
                                        content={t('user.account.logoutButton')}
                                    />
                                </Centered>
                            </Grid.Column>
                        </Grid>
                    </Content>
                </ContentContainer>
            </Body>
        );
    }
}
