import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { Body, ContentContainer, Content } from 're-cy-cle';
import { Form, Button, FormField, TextInput, Heading } from 're-cy-cle';

@observer
export default class AccountPassword extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
    };

    @observable
    input = {
        passwordOld: '',
        passwordNew: '',
    };
    @observable inputErrors = {};

    @action
    handleChange = (name, value) => {
        this.input[name] = value;
        this.inputErrors = {};
    };

    handleSubmit = () => {
        const { currentUser } = this.props.viewStore;
        currentUser
            .changePassword(this.input)
            .then(() => {
                this.props.viewStore.showSaveNotification();
            })
            .catch(err => {
                if (err.response && err.response.data.errors) {
                    this.inputErrors = err.response.data.errors;
                }
            });
    };

    render() {
        return (
            <Body>
                <ContentContainer>
                    <Content>
                        <Heading>{t('user.changePassword.title')}</Heading>
                        <Form onSubmit={this.handleSubmit}>
                            <FormField
                                label={t(
                                    'user.changePassword.field.passwordOld.label'
                                )}
                                error={this.inputErrors.password_old}
                            >
                                <TextInput
                                    name="passwordOld"
                                    type="password"
                                    onChange={this.handleChange}
                                    value={this.input.passwordOld}
                                />
                            </FormField>
                            <FormField
                                label={t(
                                    'user.changePassword.field.passwordNew.label'
                                )}
                                error={this.inputErrors.password_new}
                            >
                                <TextInput
                                    name="passwordNew"
                                    type="password"
                                    onChange={this.handleChange}
                                    value={this.input.passwordNew}
                                />
                            </FormField>
                            <FormField>
                                <Button type="submit" fullWidth>
                                    {t('user.changePassword.saveButton')}
                                </Button>
                            </FormField>
                        </Form>
                    </Content>
                </ContentContainer>
            </Body>
        );
    }
}
