import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ApiKey } from '../../store/ApiKey';
import ApiKeyEdit from '../../container/ApiKey/Edit';

@observer
export default class ApiKeyEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    componentWillMount() {
        const id = this.props.match.params.id;
        this.apiKey = new ApiKey({
            id: id ? parseInt(id) : null,
        });

        if (id == null) {
            this.apiKey.value = this.apiKey.generateKey();
        }
    }

    componentDidMount() {
        if (this.apiKey.id) {
            this.apiKey.fetch();
        }
    }

    handleBack = () => {
        this.props.viewStore.showSaveNotification();
        this.props.history.push(`/manage/key/${this.apiKey.id}/edit`);
    };

    render() {
        return <ApiKeyEdit apiKey={this.apiKey} onBack={this.handleBack} />;
    }
}
