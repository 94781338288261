import React from 'react';
import ReactDOM from 'react-dom';
import App from './container/App';
import ViewStore from 'spider/store/View';
import { theme } from './styles.js';
import { t } from './i18n';
import { BrowserRouter } from 'react-router-dom';
import { ReCyCleTheme } from 're-cy-cle';
import configureSentry from './sentry';
import { PUBLIC_URL } from './helpers';
import { api } from 'store/Base';
import { User } from 'store/User';
import 'daycy/dist/daycy.css';

// Not ideal, but works.
import 'style/semantic-ui/components/accordion.css';
import 'style/semantic-ui/components/ad.css';
import 'style/semantic-ui/components/breadcrumb.css';
import 'style/semantic-ui/components/button.css';
import 'style/semantic-ui/components/card.css';
import 'style/semantic-ui/components/checkbox.css';
import 'style/semantic-ui/components/comment.css';
import 'style/semantic-ui/components/container.css';
import 'style/semantic-ui/components/dimmer.css';
import 'style/semantic-ui/components/divider.css';
import 'style/semantic-ui/components/dropdown.css';
import 'style/semantic-ui/components/embed.css';
import 'style/semantic-ui/components/feed.css';
import 'style/semantic-ui/components/flag.css';
import 'style/semantic-ui/components/form.css';
import 'style/semantic-ui/components/grid.css';
import 'style/semantic-ui/components/header.css';
import 'style/semantic-ui/components/icon.css';
import 'style/semantic-ui/components/image.css';
import 'style/semantic-ui/components/input.css';
import 'style/semantic-ui/components/item.css';
import 'style/semantic-ui/components/label.css';
import 'style/semantic-ui/components/list.css';
import 'style/semantic-ui/components/loader.css';
import 'style/semantic-ui/components/menu.css';
import 'style/semantic-ui/components/message.css';
import 'style/semantic-ui/components/modal.css';
import 'style/semantic-ui/components/nag.css';
import 'style/semantic-ui/components/popup.css';
import 'style/semantic-ui/components/progress.css';
import 'style/semantic-ui/components/rail.css';
import 'style/semantic-ui/components/rating.css';
import 'style/semantic-ui/components/reset.css';
import 'style/semantic-ui/components/reveal.css';
import 'style/semantic-ui/components/search.css';
import 'style/semantic-ui/components/segment.css';
import 'style/semantic-ui/components/shape.css';
import 'style/semantic-ui/components/sidebar.css';
// import 'style/semantic-ui/components/site.css';
import 'style/semantic-ui/components/statistic.css';
import 'style/semantic-ui/components/step.css';
import 'style/semantic-ui/components/sticky.css';
import 'style/semantic-ui/components/tab.css';
import 'style/semantic-ui/components/table.css';
import 'style/semantic-ui/components/transition.css';
import 'style/semantic-ui/components/video.css';
import 'style/semantic-ui.css';
import 'style/react-image-crop.css';

window.t = t;
const viewStore = new ViewStore({
    api,
    user: new User(),
    socketUrl: null,
});

configureSentry(viewStore);

ReactDOM.render(
    <ReCyCleTheme theme={theme}>
        <BrowserRouter basename={PUBLIC_URL}>
            <App store={viewStore} />
        </BrowserRouter>
    </ReCyCleTheme>,
    document.getElementById('root')
);
