import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import LoginForgot from '../container/LoginForgot';

@observer
export default class LoginForgotScreen extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
    };

    render() {
        return <LoginForgot viewStore={this.props.viewStore} />;
    }
}
