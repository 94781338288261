import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {UserStore, GroupStore} from '../store/User';
import AdminOverview from "../spider/semantic-ui/Admin/Overview";
import {ItemButton} from "../spider/semantic-ui/Admin/Overview";
import UserGroupText from "../component/UserGroupText";
import {Button} from 'semantic-ui-react';

@observer
export default class CustomerOverviewScreen extends AdminOverview {
    static propTypes = {};

    constructor(...params) {
        super(...params);
        this.store = new UserStore();
        this.groupStore = new GroupStore();
    }

    componentDidMount() {
        this.store.fetch();
        this.groupStore.fetch();
    }

    title = t('user.overview.title');


    buttons = [
        {type: 'custom', callback: user => this.props.viewStore.currentUser.isSuperuser && (
                <ItemButton
                    icon="user secret"
                    label={t('tooltips.masquerade')}
                    onClick={user.masquerade.bind(user)}
                />)
    
        },
        {type: 'edit', to: (u) => `/manage/user/${u.id}/edit`},
        {type: 'hardDelete'},
    ];

    toolbar = [
        {type: 'add', to: '/manage/user/add' },
    ];

    settings = [
        // {label: '', attr: (u) => <SmallAvatar user={u}/>, collapsing: true},
        {label: t('user.overview.fullName'), attr: (u) => u.fullName, search: '.full_name:icontains'},
        {attr: 'email', search: '.email:icontains'},
        {label: t('user.field.roles.label'), attr: (u) => <UserGroupText user={u}/>, search: '.groups.name:icontains'},
        '',
    ];


    rowProps(item, i) {
        return {warning: item.isSuperuser};
    }

}


