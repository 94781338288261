import Raven from 'raven-js';
import { autorun } from 'mobx';

const options = {
    // TODO: Include version when building TMS so we can use it here
    // release: CY_CONFIG.version,
    environment: process.env.NODE_ENV,
    ignoreUrls: [
        // Chrome extensions can cause unrelated errors
        /extensions\//i,
        /^chrome:\/\//i,
    ],
};

// It is a bit misleading that Sentry is configured immediately when loading this page,
// but otherwise it might be too late to catch some errors.
const dsn = process.env.CY_FRONTEND_SENTRY_DSN;
if (dsn) {
    Raven.config(dsn, options).install();
}

export default function configureSentry(viewStore) {
    autorun(() => {
        let context;
        if (viewStore.isAuthenticated) {
            context = {
                id: viewStore.currentUser.id,
                email: viewStore.currentUser.email,
                name: viewStore.currentUser.fullName,
            };
        }
        // Call with no options to reset user context
        Raven.setUserContext(context);
    });
}
