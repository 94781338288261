import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { values, flatten } from 'lodash';
import { Body, ContentContainer, Sidebar } from 're-cy-cle';
import TrippyBackground from '../component/TrippyBackground';
import { Button, Form, FormField, TextInput, Heading } from 're-cy-cle';

@observer
export default class ResetPassword extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
        userId: PropTypes.number.isRequired,
        resetCode: PropTypes.string.isRequired,
    };

    @observable
    input = {
        password: '',
    };

    @observable errorMsg = [];
    @observable resetSuccess = false;

    handleSubmit = () => {
        const { currentUser } = this.props.viewStore;
        currentUser
            .resetPassword({
                id: this.props.userId,
                password: this.input.password,
                resetCode: this.props.resetCode,
            })
            .then(() => {
                this.resetSuccess = true;
            })
            .catch(err => {
                if (err.response && err.response.data.errors) {
                    const errors = err.response.data.errors;
                    const errorMessages = flatten(values(errors));
                    this.errorMsg = errorMessages;
                }
            });
    };

    handleChange = (name, value) => {
        this.input[name] = value;
    };

    render() {
        const successText = <p>{t('user.resetPassword.saveSuccess')}</p>;
        const form = (
            <Form onSubmit={this.handleSubmit}>
                <FormField label={t('user.field.password.label')}>
                    <TextInput
                        name="password"
                        type="password"
                        onChange={this.handleChange}
                        value={this.input.password}
                        autoFocus
                    />
                </FormField>
                <FormField error={this.errorMsg}>
                    <Button type="submit" fullWidth>
                        {t('user.resetPassword.resetButton')}
                    </Button>
                </FormField>
            </Form>
        );
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground />
                    <Sidebar>
                        <Heading>{t('user.resetPassword.title')}</Heading>
                        {this.resetSuccess ? successText : form}
                    </Sidebar>
                </ContentContainer>
            </Body>
        );
    }
}
