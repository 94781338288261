import { observable, computed } from 'mobx';
import { pick, isArray } from 'lodash';
import { Model, Store } from './Base';

export class Permission extends Model {
    static backendResourceName = 'permission';

    @observable id = null;
    @observable codename = '';
}

export class PermissionStore extends Store {
    Model = Permission;
    static backendResourceName = 'permission';
}

export class Group extends Model {
    static backendResourceName = 'group';

    @observable id = null;
    @observable name = '';

    relations() {
        return {
            permissions: PermissionStore,
        };
    }
}

export class GroupStore extends Store {
    Model = Group;
    static backendResourceName = 'group';
}

export class User extends Model {
    static backendResourceName = 'user';

    @observable id = null;
    // TODO: username and email are always the same. Hopefully we can remove username soon.
    @observable username = '';
    @observable email = '';
    @observable column = '';
    @observable firstName;
    @observable lastName = '';
    @observable password = '';
    @observable groupNames = [];
    @observable permissions = [];
    @observable isSuperuser = false;

    @computed
    get fullName() {
        if (this.firstName || this.lastName) {
            return `${this.firstName} ${this.lastName}`;
        }
        return this.username;
    }

    relations() {
        return {
            groups: GroupStore,
        };
    }

    toBackend(options) {
        const output = super.toBackend(options);
        const newData = pick(output, [
            'email',
            'first_name',
            'last_name',
            'groups',
        ]);

        if (output.password) {
            newData.password = output.password;
        }

        return newData;
    }

    masquerade() {
        return this.api.post(`/user/${this.id}/masquerade/`).then(() => {
            window.location = '/';
        });
    }

    resetRequest(username) {
        return this.api.post(`/user/reset_request/`, { username });
    }

    changePassword({ passwordOld, passwordNew }) {
        return this.api.put(`/user/change_password/`, {
            old_password: passwordOld,
            new_password: passwordNew,
        });
    }

    resetPassword({ id, password, resetCode }) {
        return this.api
            .put(`/user/${id}/reset_password/`, {
                password,
                reset_code: resetCode,
            })
            .then(() => {
                window.location = '/';
            });
    }

    hasPermission(permissions) {
        if (!isArray(permissions)) {
            return this.permissions.includes(permissions);
        }
        return this.permissions.some(groupName =>
            permissions.includes(groupName)
        );
    }

    inGroup(groups) {
        if (!isArray(groups)) {
            return this.groupNames.includes(groups);
        }
        return this.groupNames.some(groupName => groups.includes(groupName));
    }
}

export class UserStore extends Store {
    Model = User;
    static backendResourceName = 'user';
    @observable
    params = {
        order_by: 'last_name,first_name,username',
    };
}
