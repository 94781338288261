export default {
    layout: {
        title: 'Layout',
    },
    log: {
        overview: {
            title: 'Log',
        },
        field: {
            printed: {
                label: 'Geprint?',
            },
            id: {
                label: 'ID',
            },
            choice: {
                label: 'Keuze',
            },
            productcode: {
                label: 'Product Code',
            },
            codingsystem: {
                label: 'Codestelsel',
            },
            date: {
                date: 'Datum',
                time: 'Tijd',
                label: 'Datum'
            },
            errorMessage: {
                label: 'Foutmelding',
            },
            errorCode: {
                label: 'Foutcode',
            },
            keyName: {
                label: 'Key naam',
            },
            keyValue: {
                label: 'Key waarde',
            },
            keyGuid: {
                label: 'Key Guid',
            },
        },
    },
    user: {
        overview: {
            title: 'Gebruikers',
            fullName: 'Naam',
            createButton: 'Nieuwe gebruiker',
        },
        edit: {
            title: 'Gebruiker aanpassen',
        },
        create: {
            title: 'Gebruiker toevoegen',
        },
        account: {
            title: 'Account',
            logoutButton: 'Uitloggen',
            info: {
                title: 'Account Info',
            },
            password: {
                title: 'Wachtwoord veranderen',
            },
            logout: {
                title: 'Uitloggen',
                confirm: 'Weet je zeker dat je uit wilt loggen?'
            },
        },
        field: {
            email: {
                label: 'E-mailadres',
            },
            firstName: {
                label: 'Voornaam',
            },
            lastName: {
                label: 'Achternaam',
            },
            password: {
                label: 'Wachtwoord',
            },
            roles: {
                label: 'Rollen',
            },
        },
        login: {
            title: 'Inloggen',
            forgotPasswordLink: 'Wachtwoord vergeten?',
            errors: {
                invalidCredentials: 'Gebruikernaam of wachtwoord klopt niet',
                unknown: 'Onbekende fout, status code: {{status}}',
            },
            loginButton: 'Inloggen',
        },
        passwordForgot: {
            title: 'Wachtwoord vergeten',
            requestButton: 'Stuur email',
            requestedEmailText:
                'Er is een email met een wachtwoord reset code naar dit email adress verstuurd',
        },
        resetPassword: {
            title: 'Wachtwoord resetten',
            resetButton: 'Reset',
            saveSuccess: 'Wachtwoord is correct gereset.',
        },
        changePassword: {
            title: 'Wachtwoord veranderen',
            field: {
                passwordOld: {
                    label: 'Huidig wachtwoord',
                },
                passwordNew: {
                    label: 'Nieuw wachtwoord',
                },
            },
            saveButton: 'Verander wachtwoord',
        },
        role: {
            superuser: 'superadmin',
            admin: 'admin',
        },
    },
    form: {
        saveButton: 'Opslaan',
        downloadButton: 'Download',
        addButton: 'Toevoegen',
        searchLabel: 'Zoek',
        search: 'Zoek',
        selectPlaceholder: ' --- KIES --- ',
        saveLoadingButton: 'Bezig met opslaan',
        startDate: 'Van',
        endDate: 'Tot'
    },
    key: {
        overview: {
            title: 'Api keys',
            createButton: {
                label: 'Nieuwe api key',
            },
            environment: {
                label: 'Kratz Omgeving',
            },
            name: {
                label: 'Naam',
            },
            value: {
                label: 'Waarde',
            },
        },
        create: {
            title: 'Nieuwe Api Key',
        },
        edit: {
            title: 'Bewerk Api Key',
        },
        field: {
            environment: {
                label: 'Kratz Omgeving',
            },
            name: {
                label: 'Naam',
            },
            value: {
                label: 'Waarde',
            },
            guid: {
                label: 'Kratz GUID',
            },
            kijksluiter: {
                label: 'Kijksluiter',
            },
        },
    },
    parameters: {
        title: 'Parameters',
        field: {
            codingsystem: {
                label: 'Coding systeem',
            },
            zi_number: {
                label: 'Medicijnnummer',
            },
            age_input: {
                label: 'Leeftijd in',
            },
            age_years: {
                label: 'Jaren',
            },
            age_months: {
                label: 'Maanden',
            },
            age_birth_date: {
                label: 'Geboortedatum',
            },
            dosage: {
                label: 'Dosering',
            },
            gender: {
                label: 'Geslacht',
            },
            personalized: {
                label: 'Gepersonaliseerd',
            },
        },
    },
    preview: {
        title: 'Preview',
        field: {
            type: {
                label: 'Soort preview',
            },
        },
    },
    tooltips: {
        layout: 'Layout',
        edit: 'Bewerk',
        delete: 'Verwijder',
        masquerade: 'Masquerade'

    }
};
