import { observable } from 'mobx';
import { Model, Store } from './Base';
import { ApiKey } from 'store/ApiKey';
import _ from 'lodash';

export class Log extends Model {
    static backendResourceName = 'log';

    @observable id = null;
    @observable choice = '';
    @observable productcode = '';
    @observable codingsystem = '';
    @observable errorMessage = '';
    @observable errorCode = '';
    @observable date = null;
    @observable printed = null;

    casts() {
        return {};
    }

    relations() {
        return {
            key: ApiKey,
        };
    }

    toBackend(options = {}) {
        options['fields'] = [
            'id',
            'choice',
            'productcode',
            'codingsystem',
            'errorMessage',
            'errorCode',
            'date',
            'printed'
        ];
        return super.toBackend(options);
    }

    // Generate a new 32 key key
    generateKey() {
        // https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
        return _.times(32, () => _.random(35).toString(36)).join('');
    }
}

export class LogStore extends Store {
    Model = Log;
    static backendResourceName = 'log';
}
