import {
    Model as BModel,
    Store as BStore,
    BinderApi,
    Casts as BCasts,
} from 'mobx-spine';

import { toJS, action } from 'mobx';
import { uniq, mapKeys, mapValues } from 'lodash';

import moment from 'moment';

function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

class MyApi extends BinderApi {
    baseUrl = '/api/';
}

const myApi = new MyApi();

export class Model extends BModel {
    api = myApi;

    getAllFlattenedWildcardErrors() {
        let errors = this.getWildcardErrors();

        this.__activeCurrentRelations.forEach(attr => {
            errors = errors.concat(this[attr].getWildcardErrors());
        });
        return uniq(errors);
    }

    getWildcardErrors() {
        return toJS(this.backendValidationErrors['*']) || [];
    }

    /**
     * Patch to make life easiers. Instead of getting a code, and needing to translate it in the frontend, we use
     * the messages here
     *
     * @param valErrors
     */
    @action
    parseValidationErrors(valErrors) {
        const bname = this.constructor.backendResourceName;

        if (valErrors[bname]) {
            const id = this.getInternalId();
            // When there is no id or negative id, the backend may use the string 'null'. Bit weird, but eh.
            const errorsForModel =
                valErrors[bname][id] || valErrors[bname]['null'];
            if (errorsForModel) {
                const camelCasedErrors = mapKeys(errorsForModel, (value, key) =>
                    snakeToCamel(key)
                );
                const formattedErrors = mapValues(
                    camelCasedErrors,
                    valError => {
                        return valError.map(obj => {
                            return obj.message;
                        });
                    }
                );
                this.__backendValidationErrors = formattedErrors;
            }
        }

        this.__activeCurrentRelations.forEach(currentRel => {
            this[currentRel].parseValidationErrors(valErrors);
        });
    }
}

export class Store extends BStore {
    api = myApi;

    getWildcardErrors() {
        let errors = [];
        this.models.forEach(model => {
            errors = errors.concat(model.getWildcardErrors());
        });
        return errors;
    }
}

export const api = myApi;

export const Casts = {
    ...BCasts,
    decimal: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return value.replace(/,/g, '').replace('.', ',');
        },
        toJS(attr, value) {
            if (value === null || value === '') {
                return null;
            }
            return value.replace(/\./g, '').replace(',', '.');
        },
    },
    durationMinutes: {
        parse(attr, value) {
            if (value === null) {
                return null;
            }
            return moment.duration(value, 'minutes');
        },
        toJS(attr, value) {
            if (value === null) {
                return null;
            }
            // https://github.com/CodeYellowBV/mobx-spine/issues/57
            if (value === 0) {
                return 0;
            }
            return value.asMinutes();
        },
    },
};
