import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { LogStore } from 'store/Log';
import { TIME_FORMAT, DATE_FORMAT } from 'helpers';
import moment from 'moment';
import AdminOverview from "../../spider/Admin/Overview";
import { Button } from 'semantic-ui-react';
//

@observer
export default class DeviceOverview extends AdminOverview {
    store = new LogStore({
        relations: ['key'],
    });

    settings = [
        {label: t('log.field.id.label'), attr: 'id'},
        {
            label: t('log.field.choice.label'),
            sortKey: 'choice',
            attr: 'choice',
        },
        {
            label: t('log.field.productcode.label'),
            sortKey: 'productcode',
            attr: 'productcode',
        },
        {
            label: t('log.field.codingsystem.label'),
            sortKey: 'codingsystem',
            attr: 'codingsystem',
        },
        {
            label: t('log.field.date.date'),
            attr: log => moment(log.date).format(DATE_FORMAT),
        },
        {
            label: t('log.field.date.time'),
            attr: log => moment(log.date).format(TIME_FORMAT),
        },
        {label: t('log.field.errorMessage.label'), attr: 'errorMessage'},
        {label: t('log.field.errorCode.label'), attr: 'errorCode'},
        {
            label: t('log.field.keyName.label'),
            sortKey: 'key.name',
            attr: log => log.key.name,
        },
        {
            label: t('log.field.printed.label'),
            sortKey: 'log.printend',
            attr: log => log.printed ? 'Ja' : 'Nee',
        },
    ];

    buttons = [];

    filters = [
        {type: 'search'},
        {type: 'dateRange', label: t('log.field.date.label'), name: '.date:range'}
    ];


    toolbar = [
        {
            type: 'custom', callback: (item, i) => {
                const p = new URLSearchParams(this.store.params).toString();

                return (
                    <Button as="a" href={`/api/log/download/?${p}`} icon="download"
                                   content={t('form.downloadButton')}/>
                );
            }
        }
    ];
}
