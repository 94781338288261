import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';

export default class DeleteConfirm extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func
	}

	render(){
		return (
			<Confirm
                open={this.props.isOpen}
                onCancel={this.props.onCancel}
                onConfirm={this.props.onConfirm}
                cancelButton={t('hardDelete.cancel')}
                confirmButton={t('hardDelete.confirm')}
                header={t('hardDelete.header')}
                content={t('hardDelete.content')}
                size="mini"
            />
        );
	}
}
