import { observable } from 'mobx';
import { Model, Store } from './Base';
import _ from 'lodash';

export class ApiKey extends Model {
    static backendResourceName = 'key';

    @observable id = null;
    @observable environment = null;
    @observable name = null;
    @observable value = null;
    @observable guid = null;
    @observable kijksluiter = false;

    @observable screen1 = true;
    @observable screen2 = true;
    @observable screen2a = true;
    @observable screen2b = true;
    @observable screen2c = true;
    @observable screen2d = true;
    @observable screen3 = true;
    @observable screen4 = true;
    @observable screen4a = true;
    @observable screen4b = true;
    @observable screen4c = true;
    @observable screen4d = true;
    @observable screen4e = true;
    @observable screen5 = true;
    @observable screen5a = true;
    @observable screen5b = true;
    @observable screen5c = true;
    @observable screen5d = true;
    @observable screen5e = true;
    @observable screen5f = true;
    @observable screen6 = true;

    @observable showAltTitle;;

    casts() {
        return {};
    }

    toBackend(options = {}) {
        options['fields'] = [
            'id',
            'environment',
            'name',
            'value',
            'guid',
            'kijksluiter',
            'showAltTitle',
            'screen1', 'screen2', 'screen2a', 'screen2b','screen2c','screen2d',
             'screen3', 'screen4', 'screen4a','screen4b','screen4c','screen4d',
            'screen5', 'screen5a','screen5b','screen5c','screen5d','screen5e','screen5f',
            'screen6',
        ];

        return super.toBackend(options);
    }

    // Generate a new 32 key key
    generateKey() {
        // https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
        return _.times(32, () => _.random(35).toString(36)).join('');
    }
}

export class ApiKeyStore extends Store {
    Model = ApiKey;
    static backendResourceName = 'key';
}
