import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Body, ContentContainer, Sidebar } from 're-cy-cle';
import TrippyBackground from '../component/TrippyBackground';
import { Form, Button, FormField, TextInput, Heading } from 're-cy-cle';

@observer
export default class LoginForgot extends Component {
    static propTypes = {
        viewStore: PropTypes.object,
    };

    @observable submitted = false;
    @observable
    input = {
        username: '',
    };

    handleSubmit = () => {
        this.submitted = false;
        this.props.viewStore.currentUser
            .resetRequest(this.input.username)
            .then(() => {
                this.submitted = true;
            });
    };

    handleChangeInput = (name, value) => {
        this.input[name] = value;
    };

    render() {
        return (
            <Body>
                <ContentContainer>
                    <TrippyBackground />
                    <Sidebar>
                        <Heading>{t('user.passwordForgot.title')}</Heading>
                        <Form onSubmit={this.handleSubmit}>
                            <FormField label={t('user.field.email.label')}>
                                <TextInput
                                    name="username"
                                    onChange={this.handleChangeInput}
                                    value={this.input.username}
                                    autoFocus
                                />
                            </FormField>
                            <FormField>
                                <Button type="submit" fullWidth>
                                    {t('user.passwordForgot.requestButton')}
                                </Button>
                                {this.submitted && (
                                    <p>
                                        {t(
                                            'user.passwordForgot.requestedEmailText'
                                        )}
                                    </p>
                                )}
                            </FormField>
                        </Form>
                    </Sidebar>
                </ContentContainer>
            </Body>
        );
    }
}
