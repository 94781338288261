import { observable } from 'mobx';
import { Store, Model } from './Base';
import { ApiKeyStore } from './ApiKey';

export class LayoutItem extends Model {
    static backendResourceName = 'layout_component';

    @observable id = null;
    @observable slug = null;
    @observable value = null;
    @observable label = null;
    @observable apiKey = null;
    @observable notDefault = null;
    @observable defaultValue = null;

    relations() {
        return {
            apiKey: ApiKeyStore,
        };
    }

    casts() {
        return {};
    }
}

export class LayoutItemStore extends Store {
    Model = LayoutItem;
    static backendResourceName = 'layout_component';
}
