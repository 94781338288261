import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ResetPassword from '../container/ResetPassword';

@observer
export default class ResetPasswordScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    render() {
        return (
            <ResetPassword
                viewStore={this.props.viewStore}
                userId={parseInt(this.props.match.params.id)}
                resetCode={this.props.match.params.token}
            />
        );
    }
}
