import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Login from './Login';
import AppHeader from './AppHeader';
import { withRouter } from 'react-router-dom';
import Router from './Router';
import StartupError from './StartupError';
import RuntimeError from './RuntimeError';
import { AppContainer, Body } from 're-cy-cle';
import Raven from 'raven-js';

const pathsThatSkipAuthentication = [
    /^\/login\/forgot$/,
    /^\/user\/\d+\/reset-password\/[^/]+$/,
    /^\/register/,
];

@withRouter
@observer
export default class App extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object,
    };

    static childContextTypes = {
        viewStore: PropTypes.object,
    };

    getChildContext() {
        return {
            viewStore: this.props.store,
        };
    }

    componentDidCatch(err) {
        this.hasCrashed = true;
        if (process.env.CY_FRONTEND_SENTRY_DSN) {
            Raven.captureException(err);
            Raven.showReportDialog();
        }
    }

    @observable hasCrashed = false;

    render() {
        const { store, location } = this.props;

        if (this.hasCrashed) {
            return <RuntimeError />;
        }

        let content = null;
        if (
            store.isAuthenticated ||
            pathsThatSkipAuthentication.some(regex =>
                regex.test(location.pathname)
            )
        ) {
            content = <Router store={store} />;
        } else if (store.bootstrapCode === 200) {
            content = <Login viewStore={store} />;
        } else if (store.bootstrapCode !== null) {
            // The not null check is important, since we don't want to flash a startup error while the XHR request is running.
            return <StartupError code={store.bootstrapCode} />;
        }

        return (
            <AppContainer>
                <AppHeader store={store} />
                <Body>{content}</Body>
                {store.currentModal ? (
                    <store.currentModal.render
                        viewStore={store}
                        {...store.currentModal}
                    />
                ) : null}
            </AppContainer>
        );
    }
}
