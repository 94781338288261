import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Body, FormField, RadioButtons } from 're-cy-cle';
import PreviewIframe from './PreviewIframe';

@observer
export default class Preview extends Component {
    static propTypes = {
        parameters: PropTypes.object.isRequired,
        apiKey: PropTypes.object.isRequired,
        currentAgeOption: PropTypes.string.isRequired,
        personalized: PropTypes.bool.isRequired,
        ref: PropTypes.func.isRequired
    };

    // Logic for selecting the preview type
    previewOptions = [
        { value: 'mobile', label: 'Mobiel' },
        { value: 'desktop', label: 'Desktop' },
        { value: 'json', label: 'JSON' },
        { value: 'request', label: 'Kratz Request' },
        { value: 'print', label: 'Print URL'},
    ];

    // This parameters are only send if the personalized option is set.
    personalized = ['age', 'age_in_months', 'birth_date', 'gender', 'dosage'];

    @observable currentPreviewOption = 'mobile';

    // eslint-disable-next-line
    @observable iframeUrl = 'javascript:void();';


    constructor(...params) {
        super(...params);
    }

    /**
     * So, basically, sometimes the KRATZ system is slow, because of rate limiting and stuff. Now the client complain
     * that our application does not work, because our iframe is not updating. If we change the url property alone,
     * the old page is still shown. Therefore we have this hack, if we change url, we first update the iframe url to be
     * an empty page. This signals the user that we are reloading. Then the user can see that something is happening.
     * Then we change the url for the iframe for realz.
     */
    delayedRefresh = () => {
        // eslint-disable-next-line
        this.iframeUrl = 'javascript:void();';
        setTimeout(() => {
            this.iframeUrl = this.getPreviewUrl();
        }, 200);
    };

    // Alias for now
    update = this.delayedRefresh;


    componentWillReceiveProps(nextprops) {
        // this.delayedRefresh();
    }

    changePreviewOption = (name, value) => {
        this.delayedRefresh();
        this.currentPreviewOption = value;
    };

    // Builder for the url needed by the iframe for the preview.
    getPreviewUrl = () => {
        let base_url =
            '/api/medication/' + this.props.parameters.zi_number + '/';
        if (this.currentPreviewOption === 'json') {
            base_url += 'passthrough/';
        } else if (this.currentPreviewOption === 'request') {
            base_url += 'request/';
        } else if (this.currentPreviewOption === 'print') {
            base_url += 'print/';
        }

        const url_parameters = {
            key: this.props.apiKey.value,
        };

        // Add all paramters that are not the zi_number to the url
        for (const p in this.props.parameters) {
            // Zi_number is already in the url
            if (p === 'zi_number') {
                continue;
            }

            // Only include personalized prpoerties if we have selected the option to send the personalized options
            if (!this.props.personalized && this.personalized.indexOf(p) > -1) {
                continue;
            }

            // Only add the selected age parameter
            if (p === 'age' && this.props.currentAgeOption !== 'years') {
                url_parameters[p] = '';
                continue;
            }
            if (
                p === 'age_in_months' &&
                this.props.currentAgeOption !== 'months'
            ) {
                url_parameters[p] = '';
                continue;
            }
            if (
                p === 'birth_date' &&
                this.props.currentAgeOption !== 'birth_day'
            ) {
                url_parameters[p] = '';
                continue;
            }

            if (
                p === 'birth_date' &&
                typeof this.props.parameters['birth_date'] !== 'string'
            ) {
                url_parameters['birth_date'] = this.props.parameters[
                    'birth_date'
                ].format('Y-M-D');
                continue;
            }
            url_parameters[p] = this.props.parameters[p];
        }

        if (this.currentPreviewOption === 'desktop') {
            url_parameters['viewport'] = 'desktop';
        } else if (this.currentPreviewOption === 'json') {
            url_parameters['display'] = 'json';
        }

        const parameter_url = Object.entries(url_parameters)
            .map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
            )
            .join('&');

        return base_url + '?' + parameter_url;
    };

    // The styling for the iframe
    getPreviewStyle = () => {
        if (
            this.currentPreviewOption === 'json' ||
            this.currentPreviewOption === 'request'
        ) {
            return {
                width: '600px',
                height: '800px',
                // flex: 1,
                marginTop: '20px',
                border: 'none',
            };
        } else if (this.currentPreviewOption === 'desktop') {
            // TODO, check if there si a breakpoint
            return {
                width: '600px',
                height: '800px',
                // flex: 1,
                marginTop: '20px',
                border: 'none',
            };
        } else {
            return {
                width: '375px',
                height: '667px',
                // flex: 1,
                marginTop: '20px',
                border: 'none',
            };
        }
    };

    render() {
        return (
            <Body>
                <FormField label={t('preview.field.type.label')} required>
                    <RadioButtons
                        options={this.previewOptions}
                        value={this.currentPreviewOption}
                        onChange={this.changePreviewOption}
                    />
                </FormField>
                <PreviewIframe
                    style={this.getPreviewStyle()}
                    url={this.iframeUrl}
                />
            </Body>
        );
    }
}
