import React, { Component } from 'react';
import { Heading, Subheading, Button } from 're-cy-cle';
import FullScreenOverlay from '../component/FullScreenOverlay';

export default class RuntimeError extends Component {
    render() {
        return (
            <FullScreenOverlay>
                <Heading>Application crashed</Heading>
                <Subheading>
                    We’re very sorry, but the application has crashed.<br />
                    Our team has been notified.<br />
                </Subheading>
                <Button onClick={() => document.location.reload()}>
                    Reload page
                </Button>
            </FullScreenOverlay>
        );
    }
}
