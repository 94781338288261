import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import {

    Checkbox,
} from 're-cy-cle';
import styled from 'styled-components';


const screens = {
    'screen1': {},
    'screen2': {
        'screen2a': {},
        'screen2b': {},
        'screen2c': {},
        'screen2d': {}
    },
    'screen3': '',
    'screen4': {
        'screen4a': {},
        'screen4b': {},
        'screen4c': {},
        'screen4d': {},
        'screen4e': {}
    },
    'screen5': {
        'screen5a': {},
         'screen5b': {},
         'screen5c': {},
         'screen5d': {},
         'screen5e': {},
         'screen5f': {},
    },
    'screen6': {}
};

const screenNames = {
    'screen1': "Hoe herken ik dit medicijn?",
    'screen2': "Hoe werkt dit medicijn?",
    'screen2a': "Waar is het voor?",
    'screen2b': "Hoe werkt het?",
    'screen2c': "Wanneer begint de werking?",
    'screen2d': "Wat is het werkzame middel?",
    'screen3':  "Wat zijn de bijwerkingen?",
    'screen4':  "Kan ik dit medicijn gebruiken als ik...?",
    'screen4a':  "...andere medicijnen gebruik?",
    'screen4b': "...ander ziekten of klachten heb?",
    'screen4c': "...op moet letten, zoals bij auto rijden?",
    'screen4d': "...zwanger ben?",
    'screen4e': "...borstvoeding geef?",
    'screen5':  "Hoe gebruik ik het?",
    'screen5a':  "Hoe gebruik ik het?",
    'screen5b': "Hoe lang moet ik het gebruiken?",
    'screen5c': "Wat is de gebruikelijke dosering?",
    'screen5d': "Wat moet ik doen als ik een dosering vergeten ben?",
    'screen5e':  "Kan ik zomaar stoppen?",
    'screen5f': "Hoe moet ik dit medicijn bewaren?",
    'screen6': "Wat kan ik zelf doen naast dit medicijn?"
};

const Indent = styled.div`
    margin-left:30px;
`;





@observer
export default class ScreenSelect extends Component {
    static propTypes = {
        apiKey: PropTypes.object.isRequired,
    };

    setInput = (key, value) => {
        this.props.apiKey.setInput(key, value)
    };

    renderScreen = (sid, screenmap, isParentEnabled) => {

        const screenIds = screenmap == null ? [] : Object.keys(screenmap);

        const isEnabled = this.props.apiKey[sid];

        return (

            <div key={sid}>
                <Checkbox
                    onChange={this.setInput}
                    label={screenNames[sid]}
                    name={sid}
                    value={isEnabled}
                    disabled={!isParentEnabled}
                />
                <Indent>
                { screenIds.map(sid => this.renderScreen(sid, screenmap[sid], isEnabled))}
                </Indent>
            </div>
        );
    };


    render() {

        const screenIds = Object.keys(screens);
        return (
            <div>
                { screenIds.map(sid => this.renderScreen(sid, screens[sid], true)) }
            </div>
        );
    }
}
