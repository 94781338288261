import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {
    Body,
    ContentContainer,
    Content,
    Toolbar,
    MultiSelect,
} from 're-cy-cle';
import {FormField, TextInput, Form, Heading} from 're-cy-cle';
import SaveButton from '../../component/form/SaveButton';


@observer
export default class UserEdit extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        groupStore: PropTypes.object.isRequired,
        onBack: PropTypes.func.isRequired,
    };

    @action
    saveProperty = (name, value) => {
        if (name === 'groups') {
            this.props.user.groups.clear();
            const groups = this.props.groupStore.getByIds(value);
            this.props.user.setInput(name, groups);
        } else {
            this.props.user.setInput(name, value);
        }
    };

    save = () => {
        const {user, onBack} = this.props;
        user.save({onlyChanges: true}).then(onBack);
    };

    formatRoleOption(group) {
        return {
            value: group.id,
            label: t(`user.role.${group.name}`),
        };
    }

    render() {
        const user = this.props.user;

        return (
            <Body>
            <Form onSubmit={this.save}>
                <ContentContainer>
                    <Content>
                        <Heading>
                            {user.id
                                ? t('user.edit.title')
                                : t('user.create.title')}
                        </Heading>
                        <FormField
                            label={t('user.field.email.label')}
                            error={user.backendValidationErrors.username}
                            required
                        >
                            <TextInput
                                type="email"
                                name="email"
                                onChange={this.saveProperty}
                                value={user.email}
                                autoComplete={false}
                            />
                        </FormField>
                        <FormField
                            label={t('user.field.firstName.label')}
                            error={user.backendValidationErrors.firstName}
                        >
                            <TextInput
                                name="firstName"
                                onChange={this.saveProperty}
                                value={user.firstName}
                                autoComplete={false}
                            />
                        </FormField>
                        <FormField
                            label={t('user.field.lastName.label')}
                            error={user.backendValidationErrors.lastName}
                        >
                            <TextInput
                                name="lastName"
                                onChange={this.saveProperty}
                                value={user.lastName}
                                autoComplete={false}
                            />
                        </FormField>
                        <FormField
                            label={t('user.field.password.label')}
                            error={user.backendValidationErrors.password}
                            required
                        >
                            <TextInput
                                type="password"
                                name="password"
                                onChange={this.saveProperty}
                                value={user.password}
                                autoComplete={false}
                            />
                        </FormField>
                        <FormField
                            label={t('user.field.roles.label')}
                            error={user.backendValidationErrors.groups}
                        >
                            <MultiSelect
                                name="groups"
                                onChange={this.saveProperty}
                                value={user.groups.map('id')}
                                options={this.props.groupStore.map(
                                    this.formatRoleOption
                                )}
                            />
                        </FormField>
                    </Content>
                </ContentContainer>
                <Toolbar>
                    <SaveButton loading={user.isLoading}/>
                </Toolbar>
            </Form>
            </Body>
        );
    }
}
