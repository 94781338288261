import moment from 'moment';
const DATE_RANGE_FORMAT = 'YYYY-MM-DD';

export function getDateRangeFilter(store, field) {
    const param = store.params[`.${field}:range`];
    const dates = param ? param.split(',') : [];
    const offset = dates.length > 1 && dates[1] && (dates[1].includes('T') || dates[1].includes(' '));

    return {
        startDate: dates.length > 0 && dates[0] ? moment(dates[0]) : null,
        endDate: dates.length > 1 && dates[1] ? moment(dates[1]).add(offset ? -1 : 0, 'day') : null,
        offset: offset,
    };
}

export function setDateRangeFilter(store, field, startDate, endDate, offset = true) {
    const start = startDate
        ? startDate
              .clone()
              .startOf('day')
              .format(DATE_RANGE_FORMAT)
        : '';
    const end = endDate
        ? endDate
              .clone()
              .add(offset ? 1 : 0, 'day')
              .endOf('day')
              .format(DATE_RANGE_FORMAT)
        : '';
    const value = start || end ? `${start},${end}` : undefined;
    store.params = Object.assign({}, store.params, {
        [`.${field}:range`]: value,
    });
}
