import moment from 'moment';
import { each, get } from 'lodash';

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : undefined;

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_SHORT_FORMAT = 'DD-MM-YY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const DATETIME_SHORT_FORMAT = 'DD-MM-YY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

export function getDateRangeFilter(store, field) {
    const param = store.params[`.${field}:range`];
    const dates = param ? param.split(',') : [];

    return {
        startDate: dates.length > 0 && dates[0] ? moment(dates[0]) : null,
        endDate: dates.length > 1 && dates[1] ? moment(dates[1]) : null,
    };
}

export function setDateRangeFilter(store, field, startDate, endDate) {
    const start = startDate
        ? startDate
              .clone()
              .startOf('day')
              .format(DATE_RANGE_FORMAT)
        : '';
    const end = endDate
        ? endDate
              .clone()
              .endOf('day')
              .format(DATE_RANGE_FORMAT)
        : '';
    const value = start || end ? `${start},${end}` : undefined;
    store.params = Object.assign({}, store.params, {
        [`.${field}:range`]: value,
    });
}

export function addDeletedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.deleted) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}
