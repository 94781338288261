import moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {
    Grid,
    Row,
    Col,
    TextInput,
    FormField,
    NumberInput,
    RadioButtons,
    SelectInput,
    Heading,
    Subheading,
    Button,
} from 're-cy-cle';

import EditItem from './Item';
import PreviewItem from './Preview';
import SaveButton from '../../../component/form/SaveButton';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {forEach} from 'lodash';

class CustomInput extends Component {
    static propTypes = {
        onClick: PropTypes.func,
        value: PropTypes.string,
    };

    render() {
        return (
            <TextInput onFocus={this.props.onClick} value={this.props.value}/>
        );
    }
}


@observer
export default class ApiKeyLayoutEdit extends Component {
    static propTypes = {
        apiKey: PropTypes.object.isRequired,
        layoutItemStore: PropTypes.object.isRequired,
        onBack: PropTypes.func.isRequired,
    };

    renderLayoutItem = (item, id) => {
        return <EditItem layoutItem={item} key={id}/>;
    };

    // Logic and state needed for the switch of the input for the age of the patient
    ageOptions = [
        {value: 'years', label: 'Jaren'},
        {value: 'months', label: 'Maanden'},
        {value: 'birth_day', label: 'Geboortedatum'},
    ];

    @observable currentAgeOption = 'years';

    changeAgeInput = (name, value) => (this.currentAgeOption = value);

    // Logic for showing personalized info
    personalizedOptions = [
        {value: true, label: 'Ja'},
        {value: false, label: 'Nee'},
    ];

    @observable personalized = true;

    changePersonalized = (name, value) => (this.personalized = value);

    // Logic for the normal parameters that need to be passed through Kratz
    codingsystem = [
        {value: 'ZI', label: 'ZI'},
        {value: 'HP', label: 'HP'},
        {value: 'PRK', label: 'PRK'},
        {value: 'HPK', label: 'HPK'},
    ];

    gender = [
        {value: 'MALE', label: 'Man'},
        {value: 'FEMALE', label: 'Vrouw'},
    ];

    @observable
    parameters = {
        zi_number: '12148083',
        codingsystem: 'ZI',
        age_in_months: '',
        age: '',
        birth_date: moment(),
        dosage: '',
        gender: '',
    };

    previewRef = null

    saveProperty = (name, value) => {
        this.parameters[name] = value;
    };

    // Get the correct age field depending on the current age system used
    getAgeField = () => {
        if (this.currentAgeOption === 'months') {
            return (
                <FormField
                    label={t('parameters.field.age_months.label')}
                    required
                >
                    <NumberInput
                        onChange={this.saveProperty}
                        value={this.parameters.age_in_months}
                        name="age_in_months"
                    />
                </FormField>
            );
        } else if (this.currentAgeOption === 'birth_day') {
            const minDate = moment().subtract(100, 'years');
            const maxDate = moment();

            return (
                <FormField
                    label={t('parameters.field.age_birth_date.label')}
                    required
                >
                    <DatePicker
                        onChange={new_date =>
                            this.saveProperty('birth_date', new_date)
                        }
                        selected={this.parameters.birth_date}
                        name="birth_date"
                        customInput={<CustomInput/>}
                        minDate={minDate}
                        maxDate={maxDate}
                        yearDropdownItemNumber={200}
                        scrollableYearDropdown
                        showYearDropdown
                        showMonthDropdown
                        dateFormat="D/M/Y"
                    />
                </FormField>
            );
        } else {
            return (
                <FormField
                    label={t('parameters.field.age_years.label')}
                    required
                >
                    <TextInput
                        onChange={this.saveProperty}
                        value={this.parameters.age}
                        name="age"
                    />
                </FormField>
            );
        }
    };

    @observable isLayoutSaving = false;

    saveLayout = () => {
        this.isLayoutSaving = true;

        // https://github.com/CodeYellowBV/mobx-spine/issues/49
        const saves = this.props.layoutItemStore
            .filter(a => a.__changes.length > 0)
            .map(a => a.save({onlyChanges: true}));
        Promise.all(saves).then(() => {
            this.isLayoutSaving = false;
        });
    };

    renderPersonalized = () => {
        return (
            <div>
                <FormField
                    label={t('parameters.field.age_input.label')}
                    required
                >
                    <RadioButtons
                        options={this.ageOptions}
                        value={this.currentAgeOption}
                        onChange={this.changeAgeInput}
                    />
                </FormField>
                {this.getAgeField()}
                <FormField label={t('parameters.field.gender.label')} required>
                    <SelectInput
                        onChange={this.saveProperty}
                        options={this.gender}
                        value={this.parameters.gender}
                        name="gender"
                    />
                </FormField>
                <FormField label={t('parameters.field.dosage.label')} required>
                    <TextInput
                        onChange={this.saveProperty}
                        value={this.parameters.dosage}
                        name="dosage"
                    />
                </FormField>
            </div>
        );
    };

    componentDidMount() {
        forEach(document.getElementsByClassName('bindEnter'), e => {
            e.addEventListener('keypress', e => {
                console.log(e);
                if (e.keyCode === 13) {
                    this.previewRef.update()
                }
            });
        });
    }

    render() {
        const apiKey = this.props.apiKey;

        // Show productiona s green, and staging as yellow
        const environmentTone =
            apiKey.environment === 'PRODUCTION' ? 'success' : 'warning';

        const personalizedOptionsSelect = this.personalized
            ? this.renderPersonalized()
            : null;

        return (
            <Grid>
                <Row>
                    <Col>
                        <Heading>
                            <Button tone={environmentTone}>
                                {apiKey.environment}
                            </Button>{' '}
                            {apiKey.name}
                        </Heading>
                    </Col>
                </Row>
                <Row>
                    <Col xs={2}>
                        <Subheading>{t('layout.title')}</Subheading>
                        <SaveButton
                            loading={this.isLayoutSaving}
                            onClick={this.saveLayout}
                        />
                        {this.props.layoutItemStore.map(this.renderLayoutItem)}
                    </Col>
                    <Col xs={3}>
                        <Subheading>{t('parameters.title')}</Subheading>
                        <Button
                            onClick={() => this.previewRef.update()}
                        >Preview</Button>
                        <FormField
                            label={t('parameters.field.codingsystem.label')}
                            required
                        >
                            <SelectInput
                                onChange={this.saveProperty}
                                options={this.codingsystem}
                                value={this.parameters.codingsystem}
                                name="codingsystem"
                            />
                        </FormField>
                        <FormField
                            label={t('parameters.field.zi_number.label')}
                            required
                        >
                            <TextInput
                                onChange={this.saveProperty}
                                value={this.parameters.zi_number}
                                name="zi_number"
                                className="bindEnter"
                                onKeyPress={(event) => {
                                    console.log(event)
                                    debugger;
                                    if (event.key === "Enter") {
                                        this.previewRef.update()
                                    }
                                }}
                            />
                        </FormField>
                        <FormField
                            label={t('parameters.field.personalized.label')}
                            required
                        >
                            <RadioButtons
                                options={this.personalizedOptions}
                                value={this.personalized}
                                onChange={this.changePersonalized}
                            />
                        </FormField>
                        {personalizedOptionsSelect}
                    </Col>
                    <Col xs={4}>
                        <Subheading>{t('preview.title')}</Subheading>
                        <PreviewItem
                            parameters={this.parameters}
                            apiKey={this.props.apiKey}
                            currentAgeOption={this.currentAgeOption}
                            personalized={this.personalized}
                            ref={ref => (this.previewRef = ref)}
                        />
                    </Col>
                </Row>
            </Grid>
        );
    }
}
