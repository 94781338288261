import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {ApiKeyStore} from '../../store/ApiKey';
import {Button} from 'semantic-ui-react';

import AdminOverview, {ItemButton} from "../../spider/semantic-ui/Admin/Overview";
import {Link} from "react-router-dom";


@observer
export default class ApiKeyOverview extends AdminOverview {
    static propTypes = {};

    constructor(...params) {
        super(...params);

        this.store = new ApiKeyStore();
    }

    settings = [
        {
            label: t('key.overview.environment.label'),
            sortKey: 'environment',
            attr: 'environment',
        },
        {
            label: t('key.overview.name.label'),
            sortKey: 'name',
            attr: 'name',
        },
        {
            label: t('key.overview.value.label'),
            sortKey: 'value',
            attr: 'value',
        },
        {
            label:'',
        }
    ];

    buttons = [
        (u, i) => (
            <ItemButton
                key={i}
                icon="sticky note"
                to={`/manage/key/${u.id}/layout/edit`}
                as={Link}
            />
        ),
        {type: 'edit', to: (u) => `/manage/key/${u.id}/edit`},
        {type: 'hardDelete'},

    ];

    filters = [{type: 'search'}];


    toolbar = [
        {type: 'add', to: '/manage/key/add'},
    ];

}
