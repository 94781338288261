import PropTypes from 'prop-types';
import { Component } from 'react';
import { observable, set, remove } from 'mobx';
import { debounce } from 'lodash';
import { setDateRangeFilter, getDateRangeFilter } from '../helpers/dateRange';
import axios from 'axios';

export default class BaseOverviewFilter extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        afterFetch: PropTypes.func,
    };

    debouncedFetch = debounce(this._fetch, 300);

    //Fow now ugly fix: needed to use the meta again here cause the filters are using this handleChange
    //which it returns the new store fetch so we need the new response from here

    @observable meta = {};

    _fetch() {
        const { store, afterFetch } = this.props;

        if (this.cancelRequest) {
            this.cancelRequest();
        }

        // Cannot use setPage, since it doesn't forward the options properly.
        store.__state.currentPage = 1;
        store
            .fetch({
                cancelToken: new axios.CancelToken(c => {
                    this.cancelRequest = c;
                }),
            })
            .then(response => {
                set(this.meta, response.meta)
                return response;
            })
            .then(afterFetch);
    }

    _getParamName = param => {
        return param === 'search' ? param : `.${param}`;
    };

    getParamValue = name => {
        const params = this.props.store.params ? this.props.store.params : {};

        return params[this._getParamName(name)];
    };

    isEmptyValue(value) {
        return value === undefined || value === '';
    }

    handleChange = (name, value) => {
        const { store } = this.props;

        if (Array.isArray(value)) {
            value = value.join(',');
        }

        if (value === undefined || value === '') {
            remove(store.params, this._getParamName(name));
            store.updateUrlParams && store.updateUrlParams();
        } else {
            set(store.params, this._getParamName(name), value);
        }

        // We need to use a force update here, because params is an object,
        // and mobx does not track newly added / deleted keys properly.
        // This is fixed in mobx v4, so we can remove it once we have upgraded
        // to v4.
        this.forceUpdate();
        this.debouncedFetch();
    };

    generateInputProps = ({ name, multiple, forceNumber }) => {
        let value = this.getParamValue(name);
        if (multiple && value) {
            value = `${value}`.split(',');
            if (forceNumber) value = value.map(Number);
        }
        return {
            name: name,
            value: value === undefined && multiple ? [] : value,
            onChange: this.handleChange,
        };
    };

    handleDateRangeChange = (name, { startDate, endDate }) => {
        const { store } = this.props;
        setDateRangeFilter(store, name, startDate, endDate);

        if ((startDate && endDate) || (!startDate && !endDate)) {
            this.debouncedFetch();
        }

        if ((startDate === null) && (endDate === null)) {
            delete store.params[`.${name}:range`];

            store.updateUrlParams && store.updateUrlParams();
            this.forceUpdate();
            this.debouncedFetch();
        }
    };

    handleDateRangeChangeNoOffset = (name, { startDate, endDate }) => {
        const { store } = this.props;
        setDateRangeFilter(store, name, startDate, endDate, false);

        if ((startDate && endDate) || (!startDate && !endDate)) {
            this.debouncedFetch();
        }

        if ((startDate === null) && (endDate === null)) {
            delete store.params[`.${name}:range`];

            if (store.updateUrlParams) {
                store.updateUrlParams();
            }

            this.forceUpdate();
            this.debouncedFetch();
        }
    };

    generateDateRangeInputProps({ name }) {
        const dates = getDateRangeFilter(this.props.store, name);
        const offset = dates.offset;
        return {
            name,
            onChange: offset ? this.handleDateRangeChange :  this.handleDateRangeChangeNoOffset,
            startDate: dates.startDate,
            endDate: dates.endDate,
        };
    }
}
