import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AccountDetails from '../container/AccountDetails';

@observer
export default class AccountDetailsScreen extends Component {
    static propTypes = {
        viewStore: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    handleLogout = () => {
        this.props.history.push('/');
    };

    render() {
        return (
            <AccountDetails
                viewStore={this.props.viewStore}
                onLogout={this.handleLogout}
            />
        );
    }
}
