import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {TopMenu, MenuRow, NavMenu, NavItem, Logo} from 're-cy-cle';
import {Route, withRouter} from 'react-router-dom';

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
    };

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('user.account.title')}
                    to="/account/details"
                />
            </NavMenu>
        );
    };

    renderSuperuser = () => (
        <NavMenu>
            <NavItem
                title={t('user.overview.title')}
                to="/manage/user/"
                activePath="/manage/user/"
            />
            <NavItem
                title={t('key.overview.title')}
                to="/manage/key/"
                activePath="/manage/key/"
            />
            <NavItem
                title={t('log.overview.title')}
                to="/log/overview/"
                activePath="/log/overview/"
            />
        </NavMenu>
    );

    renderAdmin = () => (
        <NavMenu>
            <NavItem
                title={t('key.overview.title')}
                to="/manage/key/"
                activePath="/manage/key/"
            />
            <NavItem
                title={t('log.overview.title')}
                to="/log/overview/"
                activePath="/log/overview/"
            />
        </NavMenu>
    );

    render() {
        if (!this.props.store.isAuthenticated) {
            return (
                <TopMenu>
                    <Logo>GIP</Logo>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const {currentUser} = this.props.store;
        const version = process.env.CY_FRONTEND_GIT_VERSION;
        const accountTitle = `${currentUser.fullName} (${version})`;
        const account = <NavItem title={accountTitle} to="/account/details"/>;
        const accountSubmenu = (
            <Route path="/account" render={this.renderAccount}/>
        );

        const menu = currentUser.isSuperuser
            ? this.renderSuperuser()
            : this.renderAdmin();

        return (
            <TopMenu>
                <MenuRow>
                    <Logo>GIP</Logo>
                    {menu}
                    {account}
                </MenuRow>
                <MenuRow>{accountSubmenu}</MenuRow>
            </TopMenu>
        );
    }
}
