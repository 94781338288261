import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import {
    Body,
    ContentContainer,
    Content,
    Toolbar,
    FormField,
    Form,
    Heading,
    RadioButtons,
    Row,
    Col,
    Subheading,
    SelectInput
} from 're-cy-cle';

import {
    FormTextInput as TextInput,
} from '../../spider/semantic-ui/Form';


import SaveButton from 'component/form/SaveButton';
import ScreenSelect from './ScreenSelect';

@observer
export default class LayoutEdit extends Component {
    static propTypes = {
        apiKey: PropTypes.object.isRequired,
        onBack: PropTypes.func.isRequired,
    };

    @action
    saveProperty = (name, value) => {
        const { apiKey } = this.props;


        switch (name) {
            case 'kijksluiter':
                apiKey.kijksluiter = value;
                break;
            default:
                apiKey.setInput(name, value);
                break;
        }
    };

    save = () => {
        const { apiKey, onBack } = this.props;


        apiKey.save({ onlyChanges: true }).then(onBack);
    };

    environments = [
        { value: 'PRODUCTION', label: 'Productie' },
        { value: 'STAGING', label: 'Staging' },
        { value: 'PRODUCTION/ACCEPT', label: 'Productie (Accept)' },
        { value: 'STAGING/ACCEPT', label: 'Staging (Accept)' },
    ];

    render() {
        const apiKey = this.props.apiKey;

        return (
            <Body>
                <Form onSubmit={this.save}>
                    <ContentContainer>
                        <Content>
                            <Heading>
                                {apiKey.id
                                    ? t('key.edit.title')
                                    : t('key.create.title')}
                            </Heading>
                            <Row>
                                <Col xs>
                                    <TextInput
                                        fluid
                                        model={apiKey}
                                        name="name"
                                    />
                                </Col>
                                <Col xs>

                                        <TextInput
                                            fluid
                                            model={apiKey}
                                            name="value"
                                            disabled
                                        />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <FormField
                                        label={t('key.field.kijksluiter.label')}
                                        required
                                    >
                                        <RadioButtons
                                            name="kijksluiter"
                                            onChange={this.saveProperty}
                                            value={apiKey.kijksluiter}
                                            options={[
                                                {
                                                    value: false,
                                                    label: 'Nee',
                                                },
                                                {
                                                    value: true,
                                                    label: 'Ja',
                                                },
                                            ]}
                                        />
                                    </FormField>
                                </Col>
                                <Col xs>
                                    <FormField
                                        label={t('key.field.environment.label')}
                                        required
                                    >
                                        <SelectInput
                                            onChange={this.saveProperty}
                                            options={this.environments}
                                            value={apiKey.environment}
                                            name="environment"
                                        />
                                    </FormField>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <FormField
                                        label={t('key.field.guid.label')}
                                        required
                                    >
                                        <TextInput
                                            fluid
                                            model={apiKey}
                                            name="guid"
                                            label=""
                                        />
                                    </FormField>
                                </Col>
                                <Col xs>
                                    <FormField
                                        label="Alternatieve titel"
                                        required
                                    >
                                        <RadioButtons
                                            name="showAltTitle"
                                            onChange={this.saveProperty}
                                            value={apiKey.showAltTitle}
                                            options={[
                                                {
                                                    value: false,
                                                    label: 'Nee',
                                                },
                                                {
                                                    value: true,
                                                    label: 'Ja',
                                                },
                                            ]}
                                        />
                                    </FormField>
                                </Col>
                            </Row>
                                            <Subheading>Ingeschakelde rubrieken</Subheading>
                            <Row>
                                 <Col xs>
                                        <ScreenSelect apiKey={apiKey}/>
                                    </Col>
                            </Row>
                        </Content>
                    </ContentContainer>
                    <Toolbar>
                        <SaveButton loading={apiKey.isLoading} />
                    </Toolbar>
                </Form>
            </Body>
        );
    }
}
