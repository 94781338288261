import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
export default class Preview extends Component {
    static propTypes = {
        style: PropTypes.object.isRequired,
        url: PropTypes.string.isRequired,
    };

    render() {
        return (
            <iframe
                style={this.props.style}
                src={this.props.url}
                id="passthrough"
            />
        );
    }
}
