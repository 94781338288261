import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';
import { FormField, TextInput } from 're-cy-cle';
import BaseOverviewFilter from './BaseOverviewFilter'; // Not really the way to go... but good enough for now.

@observer
export default class CommonOverviewFilters extends BaseOverviewFilter {
    static propTypes = {
        store: PropTypes.object.isRequired,
        autoFocus: PropTypes.bool,
    };

    componentDidMount() {
        if (this.props.autoFocus) {
            // Unfortunately the `autoFocus` attribute on TextInput doesn't work here;
            // do note that it DOES work on development, but not on production.
            this.refSearch.focus();
        }
    }

    render() {
        return (
            <FormField label={t('form.searchLabel')}>
                <TextInput
                    {...this.generateInputProps({ name: 'search' })}
                    type="search"
                    onChange={this.handleChange}
                    ref={c => (this.refSearch = c)}
                />
            </FormField>
        );
    }
}
