import i18next from 'i18next';
import translationEn from './en';
import translationNl from './nl';

export function getLanguage() {
    return localStorage.getItem('language') || 'nl';
}

export function setLanguage(language) {
    return localStorage.setItem('language', language);
}

i18next.init({
    lng: getLanguage(),
    resources: {
        en: {
            translation: translationEn,
        },
        nl: {
            translation: translationNl,
        },
    },
});

export const t = i18next.t.bind(i18next);
