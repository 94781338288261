import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { User, GroupStore } from '../store/User';
import UserEdit from '../container/User/Edit';

@observer
export default class UserEditScreen extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    componentWillMount() {
        const id = this.props.match.params.id;
        this.user = new User(
            { id: id ? parseInt(id) : null },
            { relations: ['groups'] }
        );
        this.groupStore = new GroupStore();
    }

    componentDidMount() {
        this.groupStore.fetch();
        console.log('yyyyyyy');
        if (this.user.id) {
            this.user.fetch();
        }
    }

    handleBack = () => {
        this.props.viewStore.showSaveNotification();
        this.props.history.push(`/manage/user/${this.user.id}/edit`);
    };

    render() {
        return (
            <UserEdit
                user={this.user}
                groupStore={this.groupStore}
                onBack={this.handleBack}
            />
        );
    }
}
