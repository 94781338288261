import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Heading, Subheading } from 're-cy-cle';
import FullScreenOverlay from '../component/FullScreenOverlay';

@observer
export default class StartupError extends Component {
    static propTypes = {
        code: PropTypes.number.isRequired,
    };

    render() {
        return (
            <FullScreenOverlay>
                <Heading>Server error ({this.props.code})</Heading>
                <Subheading>
                    {this.props.code === 502 ? (
                        <span>
                            Server maintenance is being performed right now.
                        </span>
                    ) : (
                        <span>
                            Our server has failed us. We’re very sorry.<br />
                            A notification has been sent to us with the details
                            of this issue.
                        </span>
                    )}
                </Subheading>
            </FullScreenOverlay>
        );
    }
}
