import React from 'react';
import { Body, ContentContainer, Content, Heading } from 're-cy-cle';
import TrippyBackground from '../component/TrippyBackground';

export default () => (
    <Body>
        <ContentContainer>
            <TrippyBackground>
                <Content center>
                    <Heading color="#fff">{t('pageNotFoundMessage')}</Heading>
                </Content>
            </TrippyBackground>
        </ContentContainer>
    </Body>
);
