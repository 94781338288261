import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 're-cy-cle';
import { EditButton, AddButton, ViewButton } from 'spider/Button';

export class LinkV2toV1 extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        children: PropTypes.node,
        icon: PropTypes.bool,
        link: PropTypes.bool,
    };

    render() {
        const { icon, children, link } = this.props;

        return (
            <Button
                onClick={() => {window.location = window.location.href.slice(0, window.location.href.indexOf('v2') - 1) + this.props.to;}}
                icon={icon}
                link={link}
            >
                {children}
            </Button>
        );
    }
}

export class EditButtonV2toV1 extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { children } = this.props;

        return (
            <EditButton
                onClick={() => {window.location = window.location.href.slice(0, window.location.href.indexOf('v2') - 1) + this.props.to;}}
                icon
            >
                {children}
            </EditButton>
        );
    }
}

export class AddButtonV2toV1 extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { children } = this.props;

        return (
            <AddButton
                onClick={() => {window.location = window.location.href.slice(0, window.location.href.indexOf('v2') - 1) + this.props.to;}}
            >
                {children}
            </AddButton>
        );
    }
}

export class ViewButtonV2toV1 extends Component {
    static propTypes = {
        to: PropTypes.string.isRequired,
        icon: PropTypes.string,
        children: PropTypes.node,
    };

    render() {
        const { icon, children } = this.props;

        return (
            <ViewButton
                onClick={() => {window.location = window.location.href.slice(0, window.location.href.indexOf('v2') - 1) + this.props.to;}}
                icon={icon}
            >
                {children}
            </ViewButton>
        );
    }
}
