import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, set } from 'mobx';
import { Body, ContentContainer, Content, Toolbar, Sidebar, FormField, RadioButtons } from 're-cy-cle';
import Item from '../Admin/Overview/Item';
import OverviewTable from '../component/OverviewTable';
import PaginationControls from '../component/PaginationControls';
import { AddButton, ViewButton } from 'spider/Button';
import CommonOverviewFilters from '../component/CommonOverviewFilters';
import bindUrlParams, { encodeUrlByPathname } from 'spider/helpers/bindUrlParams';
import RightDivider from '../component/RightDivider';
import { snakeToCamel } from '../helpers';
import { AddButtonV2toV1, ViewButtonV2toV1 } from '../component/Link';


/**
 * To be called directly from a route. Normally you extend this component and
 * override attributes.
 */
@observer
export default class AdminOverview extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        viewStore: PropTypes.object.isRequired,
    };

    OverviewTable = OverviewTable
    Row = Item

    @observable meta = {};

    componentDidMount() {
        this.clearUrlBinding = bindUrlParams({
            store: this.store,
            defaultParams: this.getDefaultParams(),

        });

        this.fetch();
    }

    UNSAFE_componentWillUnMount() {
        if (this.clearUrlBinding) {
            this.clearUrlBinding();
        }
    }

    fetch() {
        return this.store.fetch()
            .then(response => {
                set(this.meta, response.meta)

                return response;
            })
            .then(this.afterFetch);
    }

    getDefaultParams() {
        return this.params;
    }

    /**
     * Backend handels deleted differently than other filters.
     */
    handleDeletedChange = (name, value) => {
        const store = this.store;

        if (value === 'true') {
            store.params[name] = 'true';
        } else {
            delete store.params[name];
        }

        // Mobx only supports already existing keys when you started @observable.
        // In this case we add / delete a key, so mobx can't properly observe
        // changes. In v4 of mobx this is "fixed" by using set / remove from
        // mobx, but we are alas still stuck in 3.1.2...
        this.forceUpdate();
        store.updateUrlParams();
        store.setPage().then(response => {
            set(this.meta, response.meta)
        });
    }

    /**
     * Generate headings from settings. For now it auto creates labels based
     * on attr.
     */
    generateHeadings(settings) {
        return settings.map(setting => {
            // Auto add label if it's missings.
            if (typeof setting === 'object' && setting.label === undefined) {
                setting.label = t(`${snakeToCamel(this.store.Model.backendResourceName)}.field.${setting.attr}.label`);
            }

            return setting;
        });
    }

    render() {
        return (
            <Body>
                <ContentContainer>
                    <Content>
                        {this.renderOverviewTable.call(this)}
                    </Content>
                    {this.renderSidebar.call(this)}
                </ContentContainer>
                {this.renderToolbar.call(this)}
            </Body>
        );
    }

    renderTFooter() {
        // TODO
        return;
    }

    renderDeletedFilter() {
        const params = this.store.params ? this.store.params : {};

        return (
            <FormField label={t('common.filter.deleted')}>
                <RadioButtons
                    name="deleted"
                    onChange={this.handleDeletedChange}
                    value={params.deleted === 'true' ? 'true' : 'false'}
                    options={[
                        { value: 'false', label: t('form.no') },
                        { value: 'true', label: t('form.yes') },
                    ]}
                />
            </FormField>
        );
    }

    renderOverviewTable() {
        const OverviewTable = this.OverviewTable;
        return (
            <OverviewTable
                store={this.store}
                headings={this.generateHeadings(this.settings)}
                tfooters={this.renderTFooter()}
                settings={this.settings}
                item={this.Row}
                TableRow={this.TableRow}
                buttons={typeof this.buttons === 'function' ? this.buttons() : this.buttons}
                next={encodeUrlByPathname(this.store, this.props.location.pathname)}
            />
        );
    }

    renderSidebar() {
        const { store, filters } = this;

        if (!filters) {
            return;
        }

        return (
            <Sidebar>
                {filters.map((item, i) => {
                    if (item.type === 'search') {
                        return <CommonOverviewFilters key={i} autoFocus store={store} />
                    }

                    if (item.type === 'boolean') {
                        return this.renderDeletedFilter();
                    }

                    return null;
                })}
            </Sidebar>
        );
    }

    renderToolbar() {
        const { store, toolbar } = this;

        if (!toolbar) {
            return;
        }

        return (
            <Toolbar>
                <PaginationControls
                    store={store}
                />
                <RightDivider store={store} />
                {toolbar.map((item, i) => {
                    if (item.type === 'add') {
                        if (!item.to.includes('#')) {
                            return (
                                <AddButton
                                    key={i}
                                    to={`${item.to}?next=${encodeUrlByPathname(store, this.props.location.pathname)}`}
                                >
                                    {item.label}
                                </AddButton>
                            );
                        } else {
                            return (
                                <AddButtonV2toV1
                                    key={i}
                                    to={`${item.to}`}
                                    icon={item.icon}
                                >
                                    {item.label}
                                </AddButtonV2toV1>
                            );
                        }
                    }

                    if (item.type === 'view') {
                        if (!item.to.includes('#')) {
                            return (
                                <ViewButton
                                    key={i}
                                    to={`${item.to}?next=${encodeUrlByPathname(store, this.props.location.pathname)}`}
                                >
                                    {item.label}
                                </ViewButton>
                            );
                        } else {
                            return (
                                <ViewButtonV2toV1
                                    key={i}
                                    to={`${item.to}`}
                                    icon={item.icon}
                                >
                                    {item.label}
                                </ViewButtonV2toV1>
                            );
                        }
                    }

                    if (item.type === 'custom') {
                        return item.callback();
                    }

                    return null;
                })}
            </Toolbar>
        );
    }
}
