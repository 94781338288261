import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { TableRow, TableData, IconCheck, Tooltip } from 're-cy-cle';
import { EditButton, DeleteButton, ViewButton, RestoreButton, DownloadButton, ReExportButton, HardDeleteButton } from 'spider/Button';
import { EditButtonV2toV1 } from 'spider/component/Link';
import styled from 'styled-components';
import { DATE_FORMAT } from 'helpers';
import { omit } from 'lodash';
import moment from 'moment';
import Permission from 'spider/component/Permission';
import DeleteConfirm from 'spider/component/DeleteConfirm';

const StyledTableData = styled(TableData)`
    ${props => {
        if (props.opacity === 'deleted') {
            return `opacity: 0.4;`;
        }
    }};
`;

const StyledTableRow = styled(TableRow)`
    ${props => props.onClick && `
        cursor: pointer;
    `};
`;

@observer
export default class AdminOverviewItem extends Component {
    static propTypes = {
        settings: PropTypes.array.isRequired,
        buttons: PropTypes.array.isRequired,
        next: PropTypes.string,
        model: PropTypes.object.isRequired,
        TableRow: PropTypes.any.isRequired,
    };

    constructor(props){
        super(props);
        this.showModal = false;
    }

    static defaultProps = {
        TableRow: StyledTableRow,
    };

    removeItem = () => {
        /*
        const model = this.props.model;
        const prompt = window.confirm('Echt verwijderen?');

        if (prompt) {
            model.delete();
        }*/
        this.showModal = true;
        this.forceUpdate();
    };

    confirmDelete = () => {
        this.showModal = false;
        this.props.model.delete();
    };

    closeModal = () => {
        this.showModal = false;
        this.forceUpdate();
    };

    restoreItem = () => {
        const model = this.props.model;
        const prompt = window.confirm('Àre you sure you wanna restore this?');

        if (prompt) {
            model.restore().then(() => model.deleted = false);
        }

        this.forceUpdate();
    };

    render() {
        const { model } = this.props;

        return (
            <this.props.TableRow model={model} {...omit(this.props, 'model', 'settings', 'buttons', 'next', 'TableRow')}>
                {this.renderData()}
                {this.renderButtons()}
                <DeleteConfirm isOpen={this.showModal} onCancel={this.closeModal} onConfirm={this.confirmDelete} />
            </this.props.TableRow>
        );
    }

    renderData = () => {
        const { settings, model } = this.props;

        return settings.map((item, i) => {
            let val = '';
            const props = Object.assign({
                key: i,
                opacity: model.deleted ? 'deleted' : '',
                style: item.config, // TODO: rename...
            }, item.props || {});

            if (item.attr !== undefined) {
                if (typeof item.attr === 'function') {
                    if (item.type === 'length') {
                        val = item.attr(model).length;
                    } else {
                        val = item.attr(model);
                    }
                } else {
                    if (moment.isMoment(model[item.attr])) {
                        val = model[item.attr].format(DATE_FORMAT);
                    } else {
                        val = model[item.attr];
                    }
                }

                if (typeof val === 'boolean') {
                    val = val ? <IconCheck /> : '';
                }

                if (item.renderTd) {
                    return item.renderTd(model, item, props);
                }

                return <StyledTableData {...props}>{val}</StyledTableData>;
            }

            return null;
        });
    }

    wrap = (item, i, show, object) => {
        if(item.permission && show){
            return (
                <Permission key={i} permission={item.permission} action="describe" field={item.field}>
                    {object}
                </Permission>
            );
        }
        return object;
    }

    renderButton = (item, i) => {
        const model = this.props.model;
        let value = '';



                    if (item.type === 'custom') {
                        const c = item.callback(model);

                        // Guard against empty callback.
                        if (c) {

                            return c;
                        }

                        return null;
                    }

                    if (item.type === 'view') {
                        return (
                            <Tooltip key={i} message={item.viewLabel ? item.viewLabel : t('tooltips.view')} >
                                <ViewButton key={i} icon to={item.to(model)} />
                            </Tooltip>
                        );
                    }

                    if (item.type === 'edit') {
                        if (!item.to(model).includes('#')) {
                            if (!model.deleted) {
                                return (
                                    <Tooltip key={i} message={item.editLabel ? item.editLabel : t('tooltips.edit')} >
                                        <EditButton
                                            key={i}
                                            icon
                                            to={`${item.to(model)}?next=${this.props.next}`}
                                        />
                                    </Tooltip>
                                );
                            }

                            return (
                                <Tooltip key={i} message={item.viewLabel ? item.viewLabel : t('tooltips.view')} >
                                    <ViewButton key={i} icon to={`${item.to(model)}?next=${this.props.next}`} />
                                </Tooltip>
                            );
                        }

                        return (
                            <Tooltip key={i} message={item.editLabel ? item.editLabel : t('tooltips.edit')} >
                                <EditButtonV2toV1 key={i} to={item.to(model)} />
                            </Tooltip>
                        );
                    }
                    if(item.type === 'hardDelete'){
                        return (
                            <Tooltip key={i} message={item.deleteLabel ? item.deleteLabel : t('tooltips.delete') } direction="se">
                                <HardDeleteButton key={i} icon onClick={() => this.removeItem(model)} />
                            </Tooltip>
                        );
                    }

                    if (item.type === 'delete') {
                        if (!model.deleted) {
                            return (
                                <Tooltip key={i} message={item.deleteLabel ? item.deleteLabel : t('tooltips.delete') } direction="se">
                                    <DeleteButton key={i} icon onClick={() => this.removeItem(model)} />
                                </Tooltip>
                            );
                        }
                        return (
                            <Tooltip key={i} message={item.restoreLabel ? item.restoreLabel : t('tooltips.restore') } direction="se">
                                <RestoreButton key={i} icon onClick={() => this.restoreItem(model)} />
                            </Tooltip>
                        );
                    }

                    if (item.type === 'download') {

                        return (
                            <Tooltip key={i} message={item.label} direction="se"><DownloadButton key={i} icon href={item.to(model)}/></Tooltip>
                        );
                    }

                    if (item.type === 'reFresh') {
                        let url = item.url(model);

                        return (
                            <Tooltip key={i} message={item.label} direction="se">
                                <ReExportButton key={i} icon onClick={() => model.reFresh(url)}/>
                            </Tooltip>
                        );
                    }

                    return value;
    }

    renderButtons = () => {
        const { buttons } = this.props;
        const show = !!localStorage.getItem('permission.showDescription');
        const result = buttons && buttons.map((item, i) => {
            return this.wrap(item, i, show, this.renderButton(item, i));
        });

        if (!buttons) {
            return null;
        }

        return (
            <TableData noWrap alignRight>
                {result}
            </TableData>
        );
    }
}
